<template lang='pug'>
v-card(v-if="Object.keys(paketStatementById || {}).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`${Object.keys(paketStatementById?.behavior)[0]}-${paketStatementById.rank ? 'positionStatement' : 'documentStatement'}`, { number: paketStatementById?.full_number })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="paketStatementById?.behavior?.viewInfoBlock" :paketStatementById="paketStatementById")
    Edit(v-if="paketStatementById?.behavior?.viewEditBlock" :paketStatementById="paketStatementById")
    //- ViewPhotoList(
    //-   v-else-if="paketStatementById?.behavior?.viewFilesBlock"
    //-   isHiddenActionsButton
    //-   :sailorDocument="paketStatementById"
    //-   documentType="paketStatementById")
</template>
<script>
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
// import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
export default {
  name: 'Document-packet-statement',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue'),
    Edit: () => import('./Edit.vue')
    // ViewPhotoList: () => import('@/components/atoms/ViewPhotoList'),
  },

  data () {
    return {
      controlButtons: [
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.paketStatementById, 'viewInfoBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.paketStatementById, 'viewEditBlock'),
          checkAccess: () => checkAccess('positionStatement', 'edit', this.paketStatementById),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-trash-can-outline',
          action: () => this.removePaketStatementById(),
          checkAccess: () => checkAccess('backoffice'),
          color: 'red',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'red', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'position-statements', query: { ...this.$route.query } }),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      paketStatementById: state => state.sailor.paketStatementById,
      isLoading: state => state.sailor.isLoading
    })
  },
  mounted () {
    this.getPacketStatementById({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getPacketStatementById', 'deletePaketStatementById']),
    async removePaketStatementById () {
      this.$swal({
        title: this.$t('Do you sure that want delete the statement'),
        icon: 'info',
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (confirm) => {
        if (confirm) {
          const response = await this.deletePaketStatementById({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response.code)) this.$router.push({ name: 'position-statements', ...this.$route.query })
        }
      })
    }
  }
}
</script>
